import React from 'react'
import IconArrowBack from './icons/IconArrowBack'
import copys from '../utils/copys'
import { useNavigate } from 'react-router-dom'

const ButtonBack = ({customPage}) => {

    const navigate = useNavigate();
    return (
        <button id='buttonBack' className='button-back' onClick={()=>customPage ? navigate(customPage):navigate(-1)}>
            <IconArrowBack/>
            {copys.buttonBack}
        </button>
    )
}

export default ButtonBack

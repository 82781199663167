
import { Routes, Route } from 'react-router-dom';
import './App.css';
import ScreenHome from './screens/ScreenHome';
import ScreenLogin from './screens/ScreenLogin';
import ScreenSignUp from './screens/ScreenSignUp';
import Layout from './screens/layout/Layout';
import ScreenOTP from './screens/ScreenOTP';
import ScreenRegister from './screens/ScreenRegister';
import { Toaster } from 'react-hot-toast';
import ScreenPromoDetail from './screens/ScreenPromoDetail';
import ScreenProfile from './screens/ScreenProfile';


function App() {

  return (
    <>
      <Toaster />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<ScreenHome />} />
          <Route path="home" element={<ScreenHome />} />
          <Route path="promo/:promoId" element={<ScreenPromoDetail />} />
          <Route path="profile" element={<ScreenProfile />} />
        </Route>
        <Route path="signup" element={<ScreenSignUp />} />
        <Route path="login" element={<ScreenLogin />} />
        <Route path="otp" element={<ScreenOTP />} />
        <Route path="register" element={<ScreenRegister />} />
        <Route path="*" element={<ScreenLogin />} />
      </Routes>
    </>
  );
}

export default App;

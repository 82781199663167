import React from 'react'
import Button from './Button'

const CardPromoStatus = ({image,buttonLabel,onClick,isWithButton,buttonStyle,labels}) => {
    const clickHandler = onClick ? onClick: ()=>{};
    const style = buttonStyle ? buttonStyle:{};
    if(isWithButton){
        return (
            <div className="card-status">
                <img src={image} alt='coupon status' className='card-status-img'/>
                <Button id='detail' style={style} enabled label={buttonLabel} onClick={clickHandler} />
            </div>
        )
    }else{
        return (
            <div className="card-status">
                <img src={image} alt='coupon status' className='card-status-img'/>
                <div className="card-status-labels">
                    <div className="card-status-labels-title">
                        {labels.title}
                    </div>
                    <div className="card-status-labels-text">
                        {labels.text}
                    </div>
                </div>
            </div>
        )
    }
    
}

export default CardPromoStatus

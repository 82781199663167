import React, { useEffect, useState } from 'react'
import ButtonBack from '../components/ButtonBack'
import copys from '../utils/copys'
import ProfileDataItem from '../components/ProfileDataItem'
import { useAuthContext } from '../hooks/useAuthContext'
import { useNavigate } from 'react-router-dom'
import Modal from '../components/modals/Modal'
import ModalLogout from '../components/modals/ModalLogout'

const ScreenProfile = () => {

    const {userData,setUserData} = useAuthContext();
    const [isModalOpen,setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    const logout = ()=>{
        setUserData({isLogged:false,otpRetries:0});
        navigate('/');
    }

    useEffect(()=>{
        if(userData.isLogged ===false){
            navigate('/');
        }
    },[])

  return (
    <div className='flex flex-col justify-start'>
        <ButtonBack/>
        <Modal isOpen={isModalOpen}>
            <ModalLogout onClickOk={()=>logout()} onClickCancel={()=>setIsModalOpen(false)} />
        </Modal>
        <div className='flex flex-col justify-start'>
            <div className="profile-title" id='profileTitle'>
                {copys.titleProfileScreen}
            </div>
            <div className="profile-data">
                <ProfileDataItem header={copys.profileHeaderName} value={userData.user?.name} id='name' />
                { userData.user?.birth_year ? <ProfileDataItem header={copys.profileHeaderYear} value={userData.user?.birth_year} id={'birthYear'} /> :'' }
                { userData.user?.gender ? <ProfileDataItem header={copys.profileHeaderGender} value={userData.user?.gender} id={'gender'} /> : '' }
            </div>
            <div className="profile-logout">
                <button id='logoutButton' onClick={()=>setIsModalOpen(true)} className='button-logout'>{copys.buttonLogout}</button>
            </div>
        </div>
    </div>
  )
}

export default ScreenProfile

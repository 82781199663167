import { useEffect, useState } from 'react';
import { getPromos } from '../services/promosService';
import { usePromoContext } from '../hooks/usePromoContext';
import Card from '../components/carousel/Card';
import copys from '../utils/copys';
import ScreenLoading from './ScreenLoading';


export default function ScreenHome() {

  const [promos,setPromos] = useState([]);
  const [showLoading,setShowLoading] =useState(false)
  const {setPromoData} = usePromoContext();

  useEffect(()=>{

    setPromoData({promoId:0,toActivate:false})
    const loadPromos = async()=>{
      const {successful, data, errors} = await getPromos();
      setShowLoading(false)
      console.log({successful,data,errors})
      if(successful){
        setPromos(data);
      }
    };

    loadPromos();
  },[]);

  return (
    <div className='flex flex-col items-center gap-5 justify-start pt-3 min-h-screen bg-[#100E9D]'>
        {
          showLoading && <ScreenLoading isHome/>
        }
        <div className="flex items-center px-6 gap-1">
          <div className="shrink-0 text-wrap w-full text-white font-libre-franklin font-bold text-3xl" id='screenHomeTitle' >{copys.screenHomeTitle}</div>
        </div>
        <div className="flex flex-col items-center px-6 gap-3">

        {
          promos?.length > 0 && promos?.map(promo=>{
            return(
              <Card key={promo.promo_id} promo={promo} status={promo.status}/>
            )
          })
        }
        </div>

    </div>
  )
}

import axios from 'axios';
import { config } from '../config/config';
import { apiErrorHandler } from "../utils/errorHandler";

export const getPromos = async () => {
    try {
        const { data } = await axios.get(config.api_url + '/v1/coupon');
        return data;
    } catch (error) {
        return apiErrorHandler(error);
    }
}

export const getPromoById = async (promoId) => {
    try {
        const { data } = await axios.get(config.api_url + '/v1/coupon/' + promoId);
        return data;
    } catch (error) {
        return apiErrorHandler(error);
    }
}

export const activatePromo = async (promoId) => {
    try {
        const { data } = await axios.post(config.api_url + '/v1/coupon/activate', { promoId });
        return data;
    } catch (error) {
        return apiErrorHandler(error);
    }
}

import React from 'react'

const IconExclamartionMark = ({fill='none'}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" >
        <path fill={fill} d="M12 16.7308C12.2289 16.7308 12.4207 16.6534 12.5755 16.4986C12.7303 16.3437 12.8077 16.1519 12.8077 15.9231C12.8077 15.6942 12.7303 15.5024 12.5755 15.3476C12.4207 15.1928 12.2289 15.1154 12 15.1154C11.7712 15.1154 11.5793 15.1928 11.4245 15.3476C11.2697 15.5024 11.1923 15.6942 11.1923 15.9231C11.1923 16.1519 11.2697 16.3437 11.4245 16.4986C11.5793 16.6534 11.7712 16.7308 12 16.7308ZM11.25 13.0769H12.75V7.07693H11.25V13.0769ZM12.0017 21.5C10.6877 21.5 9.45271 21.2506 8.29658 20.752C7.14043 20.2533 6.13475 19.5766 5.27953 18.7217C4.4243 17.8669 3.74724 16.8616 3.24836 15.706C2.74947 14.5504 2.50003 13.3156 2.50003 12.0017C2.50003 10.6877 2.74936 9.45271 3.24803 8.29658C3.7467 7.14043 4.42345 6.13475 5.27828 5.27953C6.13313 4.4243 7.13837 3.74724 8.29401 3.24836C9.44962 2.74947 10.6844 2.50003 11.9983 2.50003C13.3123 2.50003 14.5473 2.74936 15.7034 3.24803C16.8596 3.7467 17.8653 4.42345 18.7205 5.27828C19.5757 6.13313 20.2528 7.13837 20.7517 8.29401C21.2505 9.44962 21.5 10.6844 21.5 11.9983C21.5 13.3123 21.2506 14.5473 20.752 15.7034C20.2533 16.8596 19.5766 17.8653 18.7217 18.7205C17.8669 19.5757 16.8616 20.2528 15.706 20.7517C14.5504 21.2505 13.3156 21.5 12.0017 21.5Z" />
    </svg>
  )
}

export default IconExclamartionMark

import React from 'react'
import {termsAndPrivacy} from '../utils/copys';

export const TermsPrivacy = () => {

    return (
        <div className="my-5 label-signup-link">
            <span className="label-dont-have-account">
            {termsAndPrivacy.labelDisclaimerTerms[0]}
            <a href={termsAndPrivacy.termsUrl} target="_blank" rel="noopener noreferrer">{termsAndPrivacy.labelDisclaimerTerms[1]}</a>
            {termsAndPrivacy.labelDisclaimerTerms[2]}
            <a href={termsAndPrivacy.privacyUrl} target="_blank" rel="noopener noreferrer">{termsAndPrivacy.labelDisclaimerTerms[3]}</a>
            {termsAndPrivacy.labelDisclaimerTerms[4]}
            </span>
        </div>
    )
}

import React,{useState,useRef} from 'react';
import './OtpInput.css';

const inputClassName = 'otp-item input-otp';
const OtpInput = ({length,onChange,isOtpWithErrors,errorMessage}) => {

    const inputRef = useRef(Array(length).fill(null));
    const [OTP, setOTP] = useState(Array(length).fill(''));

    const handlePaste = e =>{
        e.preventDefault();
        const regexString = '^\\d{'+length +'}$';
        const regex = new RegExp(regexString);
        const pastedText = e.clipboardData.getData('text');
        if(regex.test(pastedText)){
            setOTP(pastedText)
            onChange({isComplete:true,otp:pastedText})
        }
    }


    const handleTextChange = (input, index) => {
        if((/\d/.test(input))||input===''){
            const newPin = [...OTP];
            newPin[index] = input;
            setOTP(newPin);
            let isComplete = false;
        
            if(!isOtpWithErrors){
                if(input.length=== 0){
                    inputRef.current[index].className = `${inputClassName} empty`;
                }else{
                    inputRef.current[index].className = `${inputClassName} filled`;
                }
            }
            
            // check if the user has entered the first digit, if yes, automatically focus on the next input field and so on.
    
            if (input.length === 1 && index < length - 1) {
                inputRef.current[index + 1]?.focus();
            }
        
            //when user erased the current input, so focus on the previous one
            if (input.length === 0 && index > 0) { 
                inputRef.current[index - 1]?.focus();
            }
        
            // if user has entered all the digits, grab the digits and set as an argument to the onComplete function.
            if (newPin.every((digit) => digit !== '')) {
                isComplete= true;
            }
    
            onChange({isComplete,otp:newPin.join('')})
        }
    };

    return (
        <div>
            <div className='otp'>
                {Array.from({ length }, (_, inputIndex) => (
                    <input
                        id={`otpInput_${inputIndex+1}`}
                        key={inputIndex}
                        type='text'
                        maxLength={1}
                        value={OTP[inputIndex]}
                        ref={(ref) => (inputRef.current[inputIndex] = ref )}
                        onPaste={handlePaste}
                        onChange={(e) => handleTextChange(e.target.value, inputIndex)}
                        className={'otp-item input-otp ' + (isOtpWithErrors && 'error')}
                    />
                ))}
            </div>
            {
                isOtpWithErrors && (
                    <div className="otp-message" id='otpErrorMessage'>
                        {errorMessage}
                    </div>
                )
            }
            
        </div>
        
    )
}

export default OtpInput

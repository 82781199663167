export const countryPhoneCodes = {
    'MX': '+52'
}

export const authSteps = {
    SIGNIN_SCREEN: 'SIGNIN_SCREEN',
    OTP_SIGNIN: 'OTP_SIGNIN',
    SIGNUP_SCREEN: 'SIGNUP_SCREEN',
    OTP_SIGNUP: 'OTP_SIGNUP',
    REGISTER_SCREEN: 'REGISTER_SCREEN',
}

export const otpValidationStatus = {
    INCORRECT_OTP: "INCORRECT_OTP",
    EXPIRED_OTP: "EXPIRED_OTP",
    USER_NOT_REGISTERED: "user_not_found",
}

export const toastTypes = {
    SUCCESS: 'SUCCESS',
    INFO: 'INFO',
    SYSTEM: 'SYSTEM',
    ERROR: 'ERROR',
}

export const minAndMaxUserAges = {
    min: 12,
    max: 100
}

export const promoStatus = {
    AVAILABLE: 'AVAILABLE',
    ACTIVE: 'ACTIVE',
    ONHOLD: 'ONHOLD',
    DISABLED: 'DISABLED',
    EXPIRED: 'EXPIRED,'
}
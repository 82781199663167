const copys = {
    welcomeMessage: '¡Hola! Ingresa tu número celular',
    inputPhone: 'Número celular',
    labelBtnContinuar: 'Continuar',
    labelBtnLogin: 'Iniciar sesión',
    buttonCreateAccount: 'Crear cuenta',
    dontHaveAccount: '¿No tienes cuenta?',
    labelRegister: 'Únete a Descuentón',
    labelIngressOTPTitle: 'Ingresa el código',
    labelIngressOTPDetail: 'Escribe el código que recibiste en tu dispositivo móvil',
    labelResendOTP: 'Reenviar código',
    labelDisclaimerResendOTP: '*¿Aún no llega? Te podemos reenviar hasta 2 códigos cada 24 hrs',
    labelRegisterScreenTitle: '¡Ya casi terminas!',
    labelRegisterScreenSubTitle: 'Escribe la información solicitada',
    toastCloseButton: 'Cerrar',
    footerCopyright: '© 2024 Descuentón',
    headerLoginButton: 'Iniciar sesión',
    headerProfileButton: 'Perfil',
    buttonActivateCoupon: 'Activar Cupón',
    buttonAlreadyActivated: 'Cupón activado',
    buttonAvailable24hrs: 'Disponible en 24 h',
    buttonBack: 'Regresar',
    titleProfileScreen: 'Perfil',
    profileHeaderName: 'Mi nombre',
    profileHeaderYear: 'Año en que nací',
    profileHeaderGender: 'Me identifico como',
    buttonLogout: 'Cerrar sesión',
    inputPhoneErrorMessage: 'El número debe tener 10 dígitos',
    inputAgeErrorMessage: 'Debes tener 12 años o más',
    inputAgeErrorMessage100Years: '¡Vaya! ¿Tienes más de 100 años? Verifica tu edad',
    labelAgeRadio: '¿Cómo te identificas?',
    buttonDownloadCoupon: 'Descargar cupón',
    toastErrorMessageDefault: 'Hay un fallo temporal, intenta más tarde',
    screenHomeTitle: 'Refréscate, ahorra y gana',
    expiredCardTitle: 'Promoción vencida',
    expiredCardText: 'Descuida, tenemos más opciones para ti',
    buttonSeeMoreCoupons: 'Ver más promociones',
    instructionsCard: 'Activa la promoción y presenta el código al cajero',
    promoNotAvailableTitle: 'Promoción no disponible',
    promoNotAvailableText: 'Descuida, hay más opciones para ti',
    loadingHomeText1: '¡Hola, ??!',
    loadingHomeText2: 'Estás a punto de tener las mejores ofertas',
    modalLogoutText: '¿Deseas cerrar sesión?',
    modalLogoutButtonOk: 'Sí, cerrar sesión',
    modalLogoutButtonCancel: 'No, regresar',
    loadingSpinner: 'Cargando',
    loadingPromoText: `No te preocupes,
¡Estamos generando tu promoción!`
}

export const termsAndPrivacy = {
    labelDisclaimerTerms: [
        'Al registrate aceptas los ',
        'términos, condiciones',
        ' y ',
        'políticas de privacidad',
        ' de Descuentón',
    ],
    labelTermsByPromo: [
        `Revisa los términos y condiciones de `,
        `esta promoción`
    ],
    termsFooterLabel: 'Términos y condiciones',
    privacyFooterLabel: 'Políticas de privacidad',
    termsUrl: process.env.REACT_APP_TERMS_URL,
    privacyUrl: process.env.REACT_APP_PRIVACY_URL,
}

export const genderOptions = [
    {
        label: 'Masculino',
        value: 'M'
    },
    {
        label: 'Femenino',
        value: 'F'
    },
    {
        label: 'Otro',
        value: 'O'
    },
]

export default copys;
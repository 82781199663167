import React from 'react'

import {Outlet} from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'

export default function Layout() {
  return (
    <div className="min-h-screen flex flex-col justify-between">
      <div className='flex flex-col'>
        <Header/>
        <Outlet />
      </div>
      <div>
        <Footer/>
      </div>
    </div>
  )
}

import React from 'react'
import copys, {termsAndPrivacy} from '../../utils/copys'

export default function Footer() {
  return (
    <footer className='flex flex-col items-center gap-2 bg-[#100E9D] py-6'>
      <div className="font-roboto text-sm font-normal text-white underline">
        <a id='termsLink' href={termsAndPrivacy.termsUrl} target="_blank" rel="noopener noreferrer">{termsAndPrivacy.termsFooterLabel}</a>
      </div>
      <div className="font-roboto text-sm font-normal text-white underline">
        <a id='privacyLink' href={termsAndPrivacy.privacyUrl} target="_blank" rel="noopener noreferrer">{termsAndPrivacy.privacyFooterLabel}</a>
      </div>
      <div className="font-roboto text-sm font-normal text-white">
        {copys.footerCopyright}
      </div>
    </footer>
  )
}

import React from 'react'
import './ExpiredPromoCard.css'
import CouponBrokenImg from '../assets/img/broken-promo.png'
import copys from '../utils/copys'
import Button from './Button'
import { useNavigate } from 'react-router-dom'

const ExpiredPromoCard = () => {

    const navigate = useNavigate()
    return (
        <div className='expired-card'>
            <img src={CouponBrokenImg} alt="broken coupon" className='expired-card-image' />
            <div className="expired-card-labels">
                <div className="expired-card-title">{copys.expiredCardTitle}</div>
                <div className="expired-card-text">{copys.expiredCardText}</div>
            </div>
            <Button label={copys.buttonSeeMoreCoupons} enabled onClick={()=>navigate('/home')}/>
        </div>
    )
}

export default ExpiredPromoCard

import React from 'react'
import './Toast.css'
import { toastTypes } from '../utils/constants';
import IconCheck from './icons/IconCheck';
import copys from '../utils/copys';
import toast from 'react-hot-toast';
import IconBell from './icons/IconBell';
import IconExclamartionMark from './icons/IconExclamartionMark';

const Toast = ({type,message,toastId}) => {

    let icon;
    let backgroundColorClass='system';
    switch(type){
        case toastTypes.SUCCESS:
            icon = <IconCheck/>;
            backgroundColorClass='success';
            break;
        case toastTypes.INFO:
            icon = <IconCheck/>
            backgroundColorClass='info';
            break;
        case toastTypes.SYSTEM:
            icon = <IconBell/>
            break;
        case toastTypes.ERROR:
            icon = <IconExclamartionMark/>
            backgroundColorClass='error';
            break;
        default:
            icon = <IconBell/>
            break;
    }
  return (
    <div className={`custom-toast ${backgroundColorClass}` }>
        <div className="custom-toast-icon">
            {icon}
        </div>
        <div className="custom-toast-text">
        {message}
        </div>
        <button className="custom-toast-button" onClick={()=>toast.remove(toastId)}>
            {copys.toastCloseButton}
        </button>
    </div>
  )
}

export default Toast

import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import PhoneInput from "../components/PhoneInput";
import copys from '../utils/copys'
import { useEffect, useState } from "react";
import { sendOtp } from "../services/authService";
import { Logo } from "../components/Logo";
import './ScreenLogin.css';
import { useAuthContext } from "../hooks/useAuthContext";
import toast from "react-hot-toast";
import Toast from "../components/Toast";
import { toastTypes } from "../utils/constants";


export default function ScreenLogin() {

    const [phone, setPhone] = useState('');
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const navigate = useNavigate();
    const {userData,setUserData}= useAuthContext();

    const getPhoneValue = value => {
        setPhone(value);
    }

    useEffect(()=>{
        if(userData.isLogged){
            navigate('/');
        }
    },[])

    useEffect(()=>{
        if(phone.length<14){
            setButtonEnabled(false);
        }else{
            setButtonEnabled(true);
        }
    },[phone])

    const handleClick = async()=>{
        if(phone.length<14){
            return;
        }
        const {successful,message} = await sendOtp(phone);
        if(successful){
            setUserData({...userData,phoneToValidate:phone,otpRetries:userData.otpRetries+1});
            navigate('/otp')
        }else{
            toast.custom(t => (<Toast message={message} type={toastTypes.ERROR} toastId={t.id} />))
        }
    }


  return (
    <div className="pt-10 h-screen app-container-bg-image">
        <div className="container p-3 text-center">
            <div className="my-3 mb-5">
                <Logo/>
            </div>
            <div className="my-3">
                <p className="myTitle" id='loginScreenMessage'>{copys.welcomeMessage}</p>
            </div>
            <div className="my-3 container">

                <PhoneInput onChange={getPhoneValue} />
            </div>
            <div className="my-4 px-5" style={{textAlign:'-webkit-center'}}>
                <Button id={'continue'} label={copys.labelBtnLogin} enabled={buttonEnabled} onClick={handleClick}/>
            </div>
            <div className="my-5 label-signup-link">
                <span className="label-dont-have-account" id='dontHaveAccountText'>{copys.dontHaveAccount}</span>
                <span className="label-register"> <Link id="registerLink" to={'/signup'}>{copys.labelRegister}</Link></span>
            </div>
        </div>
    </div>
  )
}

import React from 'react'
import {Logo} from '../../components/Logo'
import { useAuthContext } from '../../hooks/useAuthContext'
import copys from '../../utils/copys'
import { useLocation, useNavigate } from 'react-router-dom';

export default function Header() {

  const {userData} = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  const headerColorClass = (location.pathname ==='/'|| location.pathname==='/home') ? 'bg-[#100E9D] text-white':'';

  const handleClick = ()=>{
    if(userData.isLogged){
      navigate('/profile')
    }else{
      navigate('/login');
    }
  }
  return (
    <header id='appHeader' className={`flex justify-between items-center shrink-0 py-2 px-4 ${headerColorClass}`}>
      <Logo isNavbar/>
      <button id='headerButton' className='font-roboto font-semibold text-base py-2 px-4' onClick={handleClick}>
        { userData.isLogged ? copys.headerProfileButton:copys.headerLoginButton}
      </button>
    </header>
  )
}

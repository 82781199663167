import axios from 'axios';
import { config } from '../config/config';
import { apiErrorHandler } from "../utils/errorHandler";

export const sendOtp = async (phone) => {
    try {
        const phone_number = phone.replace(' ', '');
        console.log(phone_number.length)
        const { data } = await axios.post(config.api_url + '/v1/login/otp', { phone_number });
        return data;
    } catch (error) {
        return apiErrorHandler(error);
    }

}


export const validateOtp = async (phone, OTP) => {
    try {
        const phone_number = phone.replace(' ', '');
        const otp = Number(OTP);
        const { data } = await axios.post(config.api_url + '/v1/login/validate', { phone_number, otp });
        return data
    } catch (error) {
        return apiErrorHandler(error);
    }
}

export const signUp = async (formData) => {
    try {
        const { data } = await axios.post(config.api_url + '/v1/signup', formData);
        return data;
    } catch (error) {
        return apiErrorHandler(error);
    }
}


import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@fontsource-variable/libre-franklin';
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom'

import './output.css';

import { AuthProvider } from './context/AuthContext';
import { PromoProvider } from './context/PromoContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <PromoProvider>
      <AuthProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </AuthProvider>
    </PromoProvider>
  </Router>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react'
import QRCode from 'react-qr-code'
import Button from './Button'
import copys from '../utils/copys'
import IconDownload from './icons/IconDownload'

const containerStyle={
    display: 'flex',
    width: '328px',
    padding: 'var(--spacing-12, 12px) var(--spacing-12, 12px) 12px var(--spacing-12, 12px)',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    borderRadius: '12px',
    background: 'var(--gradient-background-light-blue, linear-gradient(230deg, #C0C0EA 0%, #EEF4FF 100%))'
}

const qrCardStyle={
    display: 'flex',
    padding: 'var(--spacing-8, 8px) var(--spacing-8, 8px) var(--spacing-4, 4px) var(--spacing-8, 8px)',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 'var(--spacing-4, 4px)',
    borderRadius: 'var(--radius-4, 4px)',
    background: 'var(--neutral-background-weak, #FFF)'
}

const codeStyle={
    color: '#000',
    textAlign: 'center',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineheight: '24px', /* 150% */
}

const onImageCownload = () => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.download = "QRCode";
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
};

const CardQR = ({code}) => {
  return (
    <div style={containerStyle}>
        <div style={qrCardStyle}>
            <QRCode
                id="QRCode"
                size={140}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={code}
                viewBox={`0 0 140 140`}
            />
            <div id='text-code' style={codeStyle}>{code}</div>
        </div>
        <Button id={'download'} enabled label={copys.buttonDownloadCoupon} icon={{icon:<IconDownload/>,position:'end'}} onClick={onImageCownload} />
    </div>
  )
}

export default CardQR

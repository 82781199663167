import React from 'react';
import {useNavigate} from 'react-router-dom'
import ChipButton from '../ChipButton';
import copys from '../../utils/copys';
import { promoStatus } from '../../utils/constants';
import { useAuthContext } from '../../hooks/useAuthContext';
import { usePromoContext } from '../../hooks/usePromoContext';
import './Carousel.css';
import { useActivateCoupon } from '../../hooks/useActivateCoupon';

export default function Card({promo,isImageOnly,status}) {

  const navigate = useNavigate();
  const {userData}=useAuthContext();
  const {setPromoData} = usePromoContext();
  const {activateCoupon} = useActivateCoupon();

  let cardBackgroundStyle = promo ? {backgroundImage: `url(${promo.banner_url})`}:{background: '#83C4FF'}

  const handleClick = activate => async event =>{
    event.stopPropagation();  
    await goToPromo(activate);
  }

  const goToPromo = async(activate)=>{
    if(isImageOnly) return;

    if(activate){
      setPromoData({promoId:promo.promo_id,toActivate:true})
      if(userData.isLogged){
        await activateCoupon(promo.promo_id)
        navigate(`/promo/${promo.promo_id}`);
      }else{
        navigate('/login');
      }
    }else{
      setPromoData({promoId:promo.promo_id,toActivate:false})
      navigate(`/promo/${promo.promo_id}`)
    }
  }

  let chipButton = '';
  if(!isImageOnly){
    if(userData.isLogged){
      switch(status){
        case promoStatus.AVAILABLE:
          chipButton=<ChipButton id={promo.promo_id} label={copys.buttonActivateCoupon} onClick={handleClick(true)} colorClass={'default'}/>
          break;
        case promoStatus.ACTIVE:
          chipButton=<ChipButton id={promo.promo_id} label={copys.buttonAlreadyActivated} colorClass={'active'} />
          break;
        case promoStatus.ONHOLD:
          chipButton=<ChipButton id={promo.promo_id} label={copys.buttonAvailable24hrs} colorClass={'onhold'} />
          break;
        case promoStatus.DISABLED:
          chipButton='';
          break;
        default:
          chipButton=<ChipButton id={promo.promo_id} label={copys.buttonActivateCoupon} onClick={handleClick(true)} colorClass={'default'}/>
          break;
      }
    }else{
      chipButton=<ChipButton id={promo.promo_id} label={copys.buttonActivateCoupon} onClick={handleClick(true)} colorClass={'default'}/>
    }
  }  

  return (
    // <div id={`card_${promo.promo_id}`} className={`bg-[#83C4FF] overflow-hidden w-4/5 h-52 flex flex-col justify-center items-center rounded-xl  bg-no-repeat bg-cover relative ${userData.isLogged && status===promoStatus.DISABLED ? ' opacity-50':''}`} onClick={handleClick(false)} >
    <div id={`card_${promo.promo_id}`} className={`carousel-card ${userData.isLogged && status===promoStatus.DISABLED ? ' disabled':''}`} onClick={handleClick(false)} style={cardBackgroundStyle} >
      {/* { promo?.banner_url && <img src={promo.banner_url} alt="banner" className='w-full h-full object-cover'/> } */}
      <div className=' absolute right-2 bottom-2' >
        {chipButton}
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ButtonBack from '../components/ButtonBack'
import Card from '../components/carousel/Card'
import {getPromoById} from '../services/promosService'
import './ScreenPromoDetail.css'
import { useAuthContext } from '../hooks/useAuthContext'
import copys from '../utils/copys'
import { promoStatus } from '../utils/constants'
import CardQR from '../components/CardQR'
import { useActivateCoupon } from '../hooks/useActivateCoupon'
import { usePromoContext } from '../hooks/usePromoContext'
import ExpiredPromoCard from '../components/ExpiredPromoCard'
import DetailInfoCard from '../components/DetailInfoCard'
import InstructionsImg from '../assets/img/Instrucciones.png';
import TermsImg from '../assets/img/Terminos-y-condiciones.png'
import CardPromoStatus from '../components/CardPromoStatus'
import ActivateCouponImg from '../assets/img/Activar-cupón.png';
import PromoNotAvailableImg from '../assets/img/Promoción-no-disponible.png';
import ScreenLoading from './ScreenLoading'

const ScreenPromoDetail = () => {

    const { userData } = useAuthContext();
    const { promoData, setPromoData } = usePromoContext();
    const navigate = useNavigate()
    const {promoId} = useParams()
    const [promo,setPromo] = useState();
    const [showLoading, setShowLoading] = useState(false);
    const {activateCoupon} = useActivateCoupon();

    const activateCouponAndRefresh = async(promotion)=>{
        const {successful,code} = await activateCoupon(promoId)
        if(successful){
            const actualPromo = promotion ? promotion:promo;
            setPromo({...actualPromo,status:promoStatus.ACTIVE,code})
        }
    }

    const goToLogin = ()=>{
        setPromoData({promoId:promo.promo_id,toActivate:true})
        navigate('/login')
    }

    const generateDetailCard =()=>{
        let detailCard;
        if(userData.isLogged){
            switch (promo?.status) {
                case promoStatus.AVAILABLE:
                    detailCard=<CardPromoStatus buttonLabel={copys.buttonActivateCoupon} onClick={()=>activateCouponAndRefresh()} isWithButton image={ActivateCouponImg}  />;
                    break;
                case promoStatus.ACTIVE:
                    detailCard=<CardQR code={promo?.code}/>
                    break;
                case promoStatus.ONHOLD:
                    detailCard= <CardPromoStatus buttonLabel={copys.buttonAvailable24hrs} buttonStyle={{opacity:0.5}} image={ActivateCouponImg} isWithButton />
                    break;
                case promoStatus.DISABLED:
                    const labels = { 
                        title: copys.promoNotAvailableTitle,
                        text:copys.promoNotAvailableText
                    };
                    detailCard=<CardPromoStatus isWithButton={false} labels={labels} image={PromoNotAvailableImg}/>
                    break;
                default:
                    detailCard=<CardPromoStatus buttonLabel={copys.buttonActivateCoupon} onClick={()=>activateCouponAndRefresh()} isWithButton image={ActivateCouponImg}  />;
                    break;
            }
        }else{
            detailCard=<CardPromoStatus buttonLabel={copys.headerLoginButton} isWithButton onClick={goToLogin} image={ActivateCouponImg} /> ;
        }
        return detailCard;
    }

    useEffect(()=>{
        const loadPromo = async(promoId)=>{
            const {successful, data, errors} = await getPromoById(promoId);
            console.log({successful,data,errors})
            if(successful){
                setPromo(data);
                if(data.status === promoStatus.AVAILABLE && promoData.toActivate){
                    setShowLoading(true)
                    await activateCouponAndRefresh(data)
                    setShowLoading(false)
                }
            }else{
                setPromo({status:promoStatus.EXPIRED})
            }
            setPromoData({promoId:0,toActivate:true})
        };
    
        loadPromo(promoId);
    },[])

    return (
        <>
            <ButtonBack customPage={'/home'}/>
            { showLoading && <ScreenLoading/>}
            <div className="flex flex-col items-center p-6 gap-4">
                {
                    promo && promo?.status !== promoStatus.EXPIRED && (
                        <>
                            <Card promo={promo} isImageOnly />
                            {
                                generateDetailCard()
                            }
                            <div className="layout-info-cards">
                                <DetailInfoCard icon={InstructionsImg} text={copys.instructionsCard} />
                                <DetailInfoCard icon={TermsImg} isLink isWhite />
                            </div>
                        </>
                    )
                }
                {
                    promo?.status === promoStatus.EXPIRED && (
                        <ExpiredPromoCard/>
                    )
                }
                
            </div>
            
        </>
    )
}

export default ScreenPromoDetail

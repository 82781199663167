import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import {CustomInput} from '../components/CustomInput'
import copys, { genderOptions } from '../utils/copys'
import { useEffect, useState } from "react";
import { sendOtp, signUp } from "../services/authService";
import { Logo } from "../components/Logo";
import './ScreenLogin.css';
import { useAuthContext } from "../hooks/useAuthContext";
import { minAndMaxUserAges, toastTypes } from "../utils/constants";
import RadioGroup from "../components/Radios";
import toast from "react-hot-toast";
import Toast from "../components/Toast";
import { usePromoContext } from "../hooks/usePromoContext";

const currentYear = new Date().getFullYear();
const maxYear = currentYear - minAndMaxUserAges.min;
const minYear = currentYear - minAndMaxUserAges.max;

export default function ScreenRegister() {


    const [buttonEnabled, setButtonEnabled] = useState(false);
    const navigate = useNavigate();
    const {userData,setUserData}= useAuthContext();
    const {promoData } = usePromoContext();

    const [formData,setFormData] = useState({
        name:'',
        birth_year:null,
        gender:null,
        phone_number:userData.phoneToValidate?.replace(' ','')
    })

    useEffect(()=>{
        if(userData.isLogged){
            navigate('/');
        }
    },[])

    useEffect(()=>{
        const {name,birth_year} = formData;
        if(name!=='' && ((birth_year <= maxYear && birth_year >= minYear)||birth_year===null)){
            setButtonEnabled(true);
        }else{
            setButtonEnabled(false);
        }
    },[formData])

    const onGenderChange = value =>{
        setFormData({...formData,gender:value});
    }

    const onNameChange = value =>{
        setFormData({...formData,name:value});
    }

    const onYearChange = value =>{
        setFormData({...formData,birth_year:value==='' ? null:value});
    }

    const onSubmit = async() =>{
        const {successful, message, data} = await signUp(formData);
        if(successful){
            setUserData({...data,isLogged:true});
            if(promoData.promoId!==0){
                navigate(`/promo/${promoData.promoId}`)
            }else{
                navigate('/home');
            }
        }else{
            toast.custom(t => (<Toast message={message} type={toastTypes.ERROR} toastId={t.id} />))
        }
    }


  return (
    <div className="min-h-screen pt-10 app-container-bg-image">
        <div className="container flex flex-col px-5 text-center">
            <div className="my-3 mb-5">
                <Logo/>
            </div>
            <div className="my-3">
                <p className="screen-register-title" id="screenRegisterTitle">{copys.labelRegisterScreenTitle}</p>
            </div>
            <div className="my-3">
                <p id="screenRegisterSubtitle">{copys.labelRegisterScreenSubTitle}</p>
            </div>
            <div className="flex flex-col justify-center items-center">
                <div className="my-3 container">
                    <CustomInput id={'inputName'} label={'¿Cómo te llamas?'} type={'text'} required onChange={onNameChange} />
                </div>
                <div className="my-3 container">
                    <CustomInput id={'inputYear'} errorMessage={copys.inputAgeErrorMessage} validateAge={true} onChange={onYearChange} regExValidate={new RegExp('^[0-9]{0,4}$')} label={'¿En qué año naciste?'} type={'number'}  />
                </div>
            </div>
            
            <div className="my-3 container">
                <RadioGroup options={genderOptions} onChange={onGenderChange}/>
            </div>
            <div className="my-3 px-5" style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column-reverse'
                }}
            >
                <Button id={'continue'} label={copys.labelBtnContinuar} enabled={buttonEnabled} onClick={onSubmit}/>
            </div>
        </div>
    </div>
  )
}

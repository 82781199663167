import React from 'react'
import copys from '../utils/copys'
import { useAuthContext } from '../hooks/useAuthContext'
import {TailSpin} from 'react-loader-spinner'

const ScreenLoading = ({isHome}) => {
    const {userData} =useAuthContext();
  return (
    <div className='px-4 pb-64 pt-20 bg-white flex z-[999] flex-col justify-center items-start gap-16 w-screen h-screen fixed inset-0'>
      <div className="px-3 font-libre-franklin text-3xl text-[#000996] font-bold">
        {
            isHome ? (
                <>
                {copys.loadingHomeText1.replace('??',userData.user?.name)}
                <br />
                <br />
                {copys.loadingHomeText2}
                </>
            ):(
                <>
                    {copys.loadingPromoText}
                </>
            )
        }
        
      </div>
      <div className='flex flex-col items-center justify-center gap-2 self-stretch'>
        <TailSpin
            visible={true}
            height="80"
            width="80"
            color="#FFB100"
            ariaLabel="tail-spin-loading"
            radius="1"
            strokeWidth={4}
            wrapperStyle={{}}
            wrapperClass=""
        />
        <div className='font-roboto text-sm font-normal text-[#0C0D0F]'>{copys.loadingSpinner}</div>
      </div>
    </div>
  )
}

export default ScreenLoading

import React from 'react'
import logoImg from '../assets/img/logo.png'
import './Logo.css'

export function Logo({isNavbar}) {
  const classLogo = isNavbar ? 'logo-header':'logo-screen mx-auto';
  return (
    <>
      <img id={isNavbar?'headerLogo':'logo'} src={logoImg} className={`d-block ${classLogo} img-fluid`} alt="Logo"></img>
    </>
  )
}

import './Button.css';

const Button = ({ label, onClick, enabled, style, icon, id }) => {
  return (
    <button id={`actionButton_${id}`} style={style?style:{}} className={'custom-button ' + (enabled ? 'enabled':'disabled')} onClick={onClick} disabled={!enabled}>
      <div className='custom-button-label'>{label}</div>
      {
        icon && (
          <div className="custom-button-icon">
            {icon?.position==='end' && icon?.icon}
          </div>
        )
      }
    </button>
  );
};

export default Button;

import { activatePromo } from '../services/promosService';
import { toastTypes } from '../utils/constants';
import copys from '../utils/copys';
import { useToast } from './useToast';

export const useActivateCoupon = () => {

    const { showToast } = useToast();

    const activateCoupon = async (promoId) => {
        const { successful, data } = await activatePromo(promoId);
        if (!successful) {
            showToast(toastTypes.ERROR, copys.toastErrorMessageDefault);
        }
        return { successful, code: data.code };
    }

    return { activateCoupon };
}
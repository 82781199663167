import React from 'react'
import './Button.css'

const ChipButton = ({label,onClick,colorClass,id}) => {
  return (
    <button id={`chipButton_${id}`} onClick={onClick} className={`custom-chip-button ${colorClass}`}>
        {label}
    </button>
  )
}

export default ChipButton

import React from 'react'
import './DetailInfoCard.css'
import { termsAndPrivacy } from '../utils/copys';

const DetailInfoCard = ({isWhite,text,icon,isLink}) => {
    const classWhite = isWhite ? 'white':'';
    let textElement = text;
    if(isLink){
        textElement = (<><span>{termsAndPrivacy.labelTermsByPromo[0]}</span><span style={{textDecorationLine: 'underline'}}>{termsAndPrivacy.labelTermsByPromo[1]}</span></>)
    }
  return (
    <div className={`card-info ${classWhite}`}>
        <img className="card-info-icon" src={icon} />
        <div className={`card-info-text ${classWhite}`}>
            {textElement}
        </div>
    </div>
  )
}

export default DetailInfoCard

import React from 'react'
import './ProfileDataItem.css'

const ProfileDataItem = ({header,value,id}) => {
  return (
    <div className='profile-data-item'>
      <div className="profile-data-item-child">
        <div className="profile-data-item-grandchild">
            <div className="profile-data-item-header" id={`${id}_header`}>
                {header}
            </div>
            <div className="profile-data-item-value" id={`${id}_value`}>
                {value}
            </div>
        </div>
      </div>
      <div className="profile-data-divider-parent">
        <div className="profile-data-divider-child"/>
      </div>
    </div>
  )
}

export default ProfileDataItem

import React from 'react'

const Modal = ({ children, isOpen, onClose, hasCloseButton }) => {
    return (
        <>
            {isOpen && (
                <div className="bg-black/50 w-screen h-screen fixed inset-0 flex flex-col justify-center items-center">
                    <div>
                        {hasCloseButton && (
                            <span className="close-btn" onClick={onClose}>
                                &times;
                            </span>
                        )}
                    {children}
                    </div>
                </div>
            )}
        </>
    );
  };

export default Modal

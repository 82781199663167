import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import copys from '../utils/copys';
import Button from '../components/Button'
import { validateOtp } from '../services/authService';
import {Logo} from '../components/Logo'
import './ScreenOTP.css';
import { useAuthContext } from '../hooks/useAuthContext';
import { otpValidationStatus } from '../utils/constants';
import {sendOtp} from '../services/authService'
import OtpInput from '../components/OtpInput'
import { usePromoContext } from '../hooks/usePromoContext';

export default function ScreenOTP() {

    const navigate = useNavigate();
    const {userData,setUserData} =useAuthContext();
    const { promoData } = usePromoContext();
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const [isOtpWithErrors,setIsOtpWithErrors] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');

    useEffect(()=>{
        if(userData.isLogged){
            navigate('/');
        }
    },[])

    const validateOTP = async(otp)=>{
        const {successful, message, errors, data} = await validateOtp(userData.phoneToValidate,otp);
        if(successful){
            setUserData({...data,isLogged:true});
            if(promoData.promoId!==0){
                navigate(`/promo/${promoData.promoId}`)
            }else{
                navigate('/home');
            }
        }else{
            setButtonEnabled(false);
            setIsOtpWithErrors(true)
            errors.forEach(e=>{
                setErrorMessage(e.detail);
                switch (e.code) {
                    case otpValidationStatus.INCORRECT_OTP:
                        console.log({message:e.detail,code:e.code})
                        break;
                    case otpValidationStatus.EXPIRED_OTP:
                        console.log({message:e.detail,code:e.code})
                        break;
                    case otpValidationStatus.PHONE_NOT_FOUND:
                        console.log({message:e.detail,code:e.code})
                        break;
                    case otpValidationStatus.USER_NOT_REGISTERED:
                        navigate('/register')
                        break;
                    default:
                        console.log({message:e.detail,code:e.code})
                        break;
                }
            })
        }
    }

    const handleOtpChange = ({isComplete,otp})=>{
        if(isComplete){
            setButtonEnabled(true);
            validateOTP(otp);
        }else{
            setIsOtpWithErrors(false);
            setButtonEnabled(false);
        }
    }

    const resendCode = async()=>{
        const {successful,message} = await sendOtp(userData.phoneToValidate);
        if(successful){
            setUserData({...userData,otpRetries:userData.otpRetries+1});
        }else{
            console.log(message);
        }
    }

  return (
    <div style={{justifyContent:'unset'}} className="pt-10 h-screen app-container-bg-image">
        <div className="container p-2">
            <div className="m-2 mb-5">
                <Logo/>
            </div>
            <div className="mb-2">
                <p id='labelIngressOtp'>{copys.labelIngressOTPDetail}</p>
            </div>
            <div className="mb-2">
                <OtpInput
                    length={6}
                    onChange={handleOtpChange}
                    isOtpWithErrors={isOtpWithErrors}
                    errorMessage={errorMessage}
                />
            </div>
            <div className="mb-2 small-note" id='disclaimerOtp'>
                {copys.labelDisclaimerResendOTP}
            </div>
            <div className="mb-2">
                <button id='resendCodeButton' disabled={userData.otpRetries >= 2} className={`resend-code ${userData.otpRetries >= 2 && 'disabled'}`} onClick={resendCode} >{copys.labelResendOTP}</button>
            </div>
            <div className="mb-2" style={{textAlign:'-webkit-center'}}>
                <Button id={'continue'} label={copys.labelBtnContinuar} enabled={buttonEnabled} onClick={validateOTP}/>
            </div>
        </div>
    </div>
    )
}

import React, {useRef, useState} from 'react';
import copys from '../utils/copys';
import { countryPhoneCodes } from '../utils/constants';
import IconExclamartionMark from './icons/IconExclamartionMark';


const regex =/^[0-9]{0,10}$/;
const countryCode='MX';

const changeLabelChildrenStyles = (labelRef,isFocused)=>{
  if (labelRef && labelRef.children) {
    Array.from(labelRef.children).forEach(child => {
      if(isFocused){
        child.style.fontSize='12px';
        child.style.lineHeight='16px';
      }else{
        child.style.fontSize='16px';
        child.style.lineHeight='24px';
      }
    });
  }
}

const PhoneInput = ({ onChange }) => {

    const inputDivRef = useRef(null)
    const inputRef = useRef(null)
    const labelRef = useRef(null)

    const [inputValue, setInputValue] = useState('');
    const [hasError,setHasError] = useState(false);

    const handleChange = ({target:{value}}) => {
      if(value===countryPhoneCodes[countryCode]){
        setInputValue('');
        onChange(value)
      }else{
        let phoneNumber = value.replace(`${countryPhoneCodes[countryCode]} `,'');
        if(regex.test(phoneNumber)){
          setInputValue(`${countryPhoneCodes[countryCode]} ${phoneNumber}`);
          onChange(value)
        }
        if(hasError && phoneNumber.length===10){
          setHasError(false);
        }
      }
    };

    const handleOnFocus = ()=>{
      inputDivRef.current.style.display='flex';
      changeLabelChildrenStyles(labelRef.current,true);
      inputRef.current.focus();
    }

    const handleOnBlur = ()=>{
      if(inputValue===''){
        inputDivRef.current.style.display='none';
        changeLabelChildrenStyles(labelRef.current,false);
      }else{
        inputDivRef.current.style.display='flex';
        if(inputValue.length<14){
          setHasError(true)
        }
      }
    }

  return (

    <div className='flex container flex-col items-center gap-1'>
      <div className="flex items-start container gap-1 justify-center">
        <div tabIndex={0} className={`rounded-lg border border-solid flex p-4 items-center justify-between gap-2 h-14 container basis-3/4 focus-within:border-2 ${hasError? 'border-[#FA7490]':'border-[#FFE773]'}`} onFocus={handleOnFocus} >
          <div className="flex items-center gap-2 justify-start">
            <div className="flex flex-col justify-center items-start">
              <div className="flex items-start gap-1 self-stretch" ref={labelRef}>
                <span className="text-white font-roboto text-base font-normal">
                  {copys.inputPhone}
                </span>
                <span className="text-[#76A8FF] font-roboto font-normal text-base">
                  *
                </span>
              </div>
              <div className="items-start gap-1 self-stretch hidden" ref={inputDivRef}>
                <input 
                  id={'inputPhone'}
                  ref={inputRef}
                  value={inputValue}
                  onChange={handleChange} 
                  type="text" 
                  className='text-white font-roboto text-base font-normal text-start' 
                  onBlur={handleOnBlur}
                />
              </div>
            </div>
          </div>
          <div className={` w-6 h-6 shrink-0 ${hasError? '':'invisible'}`}>
            <IconExclamartionMark fill='#FA7490' />
          </div>
        </div>
      </div>
      <div className="flex container justify-between items-start self-stretch">
        <div id='inputPhoneErrorMessage' className={`basis-3/4 container font-roboto font-normal text-xs ${hasError? 'text-[#FA7490]':'text-white'}`}>
          {copys.inputPhoneErrorMessage}
        </div>
      </div>
    </div>
  );
};



export default PhoneInput;

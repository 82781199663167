import axios from 'axios';

export const apiErrorHandler = error => {
    if (axios.isAxiosError(error)) {
        console.log('AxiosError', error);
        const errorResponse = error.response?.data;
        if (errorResponse) {
            return errorResponse;
        }
    }
    console.error(error)
    return { successful: false, message: 'Ocurrió un error inesperado', errors: [error], data: {} };
}
import React from 'react'

const IconBell = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <mask id="mask0_302_75" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect width="24" height="24" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_302_75)">
            <path d="M4.50003 18.8846V17.3846H6.30773V9.92307C6.30773 8.57821 6.72279 7.38911 7.55291 6.35577C8.38304 5.32244 9.44875 4.66155 10.75 4.3731V3.75002C10.75 3.40281 10.8714 3.10767 11.1143 2.86462C11.3571 2.62156 11.652 2.50002 11.9989 2.50002C12.3458 2.50002 12.641 2.62156 12.8846 2.86462C13.1282 3.10767 13.25 3.40281 13.25 3.75002V4.3731C14.5513 4.66155 15.617 5.32244 16.4471 6.35577C17.2772 7.38911 17.6923 8.57821 17.6923 9.92307V17.3846H19.5V18.8846H4.50003ZM11.9983 21.6923C11.5007 21.6923 11.0753 21.5153 10.7221 21.1613C10.3689 20.8073 10.1923 20.3817 10.1923 19.8846H13.8077C13.8077 20.3833 13.6305 20.8093 13.2762 21.1625C12.9218 21.5157 12.4959 21.6923 11.9983 21.6923ZM7.80768 17.3846H16.1923V9.92307C16.1923 8.76537 15.783 7.77723 14.9644 6.95865C14.1458 6.14005 13.1577 5.73075 12 5.73075C10.8423 5.73075 9.85416 6.14005 9.03558 6.95865C8.21698 7.77723 7.80768 8.76537 7.80768 9.92307V17.3846Z" fill="white"/>
        </g>
    </svg>
  )
}

export default IconBell

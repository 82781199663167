import {  useNavigate } from "react-router-dom";
import Button from "../components/Button";
import PhoneInput from "../components/PhoneInput";
import copys from '../utils/copys'
import { useEffect, useState } from "react";
import { sendOtp } from "../services/authService";
import { Logo } from "../components/Logo";
import './ScreenLogin.css';
import { useAuthContext } from "../hooks/useAuthContext";
import { TermsPrivacy } from "../components/TermsPrivacy";


export default function ScreenSignUp() {
    
    const [phone, setPhone] = useState('');
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const navigate = useNavigate();
    const {userData,setUserData}= useAuthContext();
    
    const getPhoneValue = value => {
        setPhone(value);
    }

    useEffect(()=>{
      if(userData.isLogged){
          navigate('/');
      }
    },[])

    useEffect(()=>{
        if(phone.length<14){
            setButtonEnabled(false);
        }else{
            setButtonEnabled(true);
        }
    },[phone])

    const handleClick = async()=>{
      if(phone.length<14){
          return;
      }
      const {successful,message} = await sendOtp(phone);
      if(successful){
          setUserData({...userData,phoneToValidate:phone,otpRetries:userData.otpRetries+1});
          navigate('/otp')
      }else{
          console.log(message);
      }
    }


  return (
    <div className="h-screen pt-10 app-container-bg-image">
        <div className="container p-3 text-center">
            <div className="my-3 mb-5">
                <Logo/>
            </div>
            <div className="my-3">
                <p className="myTitle">{copys.welcomeMessage}</p>
            </div>
            <div className="my-3">
                <PhoneInput required onChange={getPhoneValue} />
            </div>
            <div className="my-3 px-5" style={{textAlign:'-webkit-center'}}>
                <Button label={copys.buttonCreateAccount} enabled={buttonEnabled} onClick={handleClick}/>
            </div>
            <TermsPrivacy />
        </div>
    </div>
  )
}
